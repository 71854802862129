import React from 'react';

function C() {
	return (
		<div data-spec="c-asset">
			<pre>
				{`
          CURL *hnd = curl_easy_init();

          curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "POST");
          curl_easy_setopt(hnd, CURLOPT_URL, "https://cisiot.att.com/EAI/oauth/token");

          struct curl_slist *headers = NULL;
          headers = curl_slist_append(headers, "Authorization: Basic ZWFpLWNsaWVudDo=");
          headers = curl_slist_append(headers, "Content-Type: application/x-www-form-urlencoded");
          curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

          curl_easy_setopt(hnd, CURLOPT_POSTFIELDS,
          "username=USERNAME&password=PASSWORD&grant_type=password");

          CURLcode ret = curl_easy_perform(hnd);
        `}
			</pre>
		</div>
	);
}

export default C;
