import React from 'react';

function GoLang() {
	return (
		<div data-spec="go-lang-asset">
			<pre>
				{`
          package main

          import (
            "fmt"
            "strings"
            "net/http"
            "io/ioutil"
          )

          func main() {

            url := "https://cisiot.att.com/EAI/oauth/token"

            payload := strings.NewReader("username=USERNAME&password=PASSWORD&grant_type=password")

            req, _ := http.NewRequest("POST", url, payload)

            req.Header.Add("Content-Type", "application/x-www-form-urlencoded")
            req.Header.Add("Authorization", "Basic ZWFpLWNsaWVudDo=")

            res, _ := http.DefaultClient.Do(req)

            defer res.Body.Close()
            body, _ := ioutil.ReadAll(res.Body)

            fmt.Println(res)
            fmt.Println(string(body))

          }
        `}
			</pre>
		</div>
	);
}

export default GoLang;
