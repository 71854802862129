import React from 'react';

function Python() {
	return (
		<div data-spec="python-asset">
			<pre>
				{`
          import requests

          url = "https://cisiot.att.com/EAI/oauth/token"

          payload = "username=USERNAME&password=PASSWORD&grant_type=password"
          headers = {
              'Content-Type': "application/x-www-form-urlencoded",
              'Authorization': "Basic ZWFpLWNsaWVudDo="
              }

          response = requests.request("POST", url, data=payload, headers=headers)

          print(response.text)˙`}
			</pre>
		</div>
	);
}

export default Python;
